import React, { useEffect, useState } from "react";
import { Drawer, Tree, Button, Table, Descriptions, notification, Switch } from "antd";
import { DownloadOutlined, FolderOutlined, FileOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType, Requirement } from "../utils/types";
import { purchaseorderfilesUrl, requirementfilesURL, requirementsdetailurl } from "../utils/network";
import FileSaver from "file-saver";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

interface FileData {
  id: number;
  requirement: string;
  folder: any;
  pdf_file: string;
  created_at: string;
}

interface RequirementItem {
  id: number|any;
  product: string;
  quantity: number;
  requirement_date: string;
  unit: number;
  selection: boolean;
}



interface RequirementDrawerProps {
  requirement: Requirement|null;
  visible: boolean;
  onClose: () => void;
}

const RequirementDrawer: React.FC<RequirementDrawerProps> = ({ requirement, visible, onClose }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileData[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);

  const [requirementItems, setRequirementItems] = useState<RequirementItem[]>([]);
  
  const fetchRequirementItems = async () => {
    if (requirement?.id) {
      try {
        const headers = getAuthToken() as AuthTokenType;
        const response = await axios.post(
          `${requirementsdetailurl}get_by_requirement_id/`, // Updated endpoint for POST request
          { requirement_id: requirement.id }, // Send requirement_id in the request body
          headers // Add headers here
        );
  
        setRequirementItems(response.data); // Update the items dynamically
      } catch (error) {
        console.error("Error fetching requirement items:", error);
      }
    }
  };

  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // New loading state
  const [designation, setDesignation] = useState<string | null>(null);


  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Add the Requirement Title and Description
    doc.setFontSize(18);
    doc.text(`Request for Quotation - ID: ${requirement?.id || 'N/A'}`, 10, 10);
    doc.setFontSize(12);
    doc.text(`Description: ${requirement?.item_description || 'No description available'}`, 10, 20);
    doc.text(`Tender ID: ${requirement?.r_id || 'No ID available'}`, 10, 30);
  
    // Add Requirement Details in Descriptions
    doc.text(`Requirement Type: ${requirement?.requirement_type}`, 10, 40);
    doc.text(`PO Number: ${requirement?.po_number}`, 10, 50);
    doc.text(`Sub Service: ${requirement?.sub_service}`, 10, 60);
    doc.text(`Sub Supplies: ${requirement?.sub_supplies}`, 10, 70);
    doc.text(`Business Line: ${requirement?.business_line.name}`, 10, 80);
    doc.text(`Category: ${requirement?.category}`, 10, 90);
    doc.text(`Subcategory: ${requirement?.subcategory}`, 10, 100);
    doc.text(`Buyer: ${requirement?.buyer?.fullname || 'N/A'}`, 10, 110);
    doc.text(`Status: ${requirement?.status?.name || 'N/A'}`, 10, 120);
    doc.text(`Supplier: ${requirement?.supplier || 'N/A'}`, 10, 130);
    doc.text(`Net Value: ${requirement?.net_value || 'N/A'}`, 10, 140);
    doc.text(`Currency: ${requirement?.currency?.name || 'N/A'}`, 10, 150);
  
    // Add the Requirement Items Table using autoTable
    autoTable(doc, {
      startY: 160, // Start from this Y position
      head: [['Product', 'Quantity', 'Requirement Date', 'Unit', 'Selection']],
      body: requirementItems.map(item => [
        item.product,
        item.quantity,
        moment(item.requirement_date).format('MM-DD-YYYY'),
        item.unit,
        item.selection ? 'Selected' : 'Not Selected',
      ]),
    });
  
    // Save the PDF
    doc.save(`RFQ_Details_${requirement?.id}.pdf`);
  };


  useEffect(() => {
    // Fetch designation and userRole from localStorage
    const storedDesignation = localStorage.getItem('token5');
    const storedUserRole = localStorage.getItem('token1');
    setDesignation(storedDesignation);
    setUserRole(storedUserRole);
    setLoading(false); // Set loading to false after fetching data
  }, []);



  const fetchFiles = async () => {
    try {
      const data = { requirement_id: requirement?.id };
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post<FileData[]>(requirementfilesURL + '/get_files_by_requirement/', data, headers);
      setFiles(response.data);
      const uniqueFolders = Array.from(new Set(response.data.map((file: FileData) => file.folder)));
      const newTreeData = uniqueFolders.map((folder: string) => ({
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FolderOutlined style={{ color: "#1890ff", marginRight: 8 }} />
            <span>{folder}</span>
          </div>
        ),
        key: folder,
        children: response.data
          .filter((file: FileData) => file.folder === folder)
          .map((file: FileData) => ({
            title: (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <FileOutlined style={{ color: "#52c41a", marginRight: 8 }} />
                <span style={{ flex: 1 }}>{file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1)}</span>
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(file.pdf_file, file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1))}
                />
              </div>
            ),
            key: file.pdf_file,
          }))
      }));
      setTreeData(newTreeData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleSelectionChange = async (itemId: number, selected: boolean) => {
    const headers = getAuthToken() as AuthTokenType;
    try {
      const response = await axios.put(`${requirementsdetailurl}${itemId}/`, { selection: selected }, headers);
      if (response) {
        notification.success({
          message: t("Selection Updated"),
        });
        fetchRequirementItems();

      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"),
      });
    }
  };

  const columns = [
    {
      title: t("Product"),
      dataIndex: "product",
      key: "product",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("Requirement Date"),
      dataIndex: "requirement_date",
      key: "requirement_date",
      render: (text: string) => moment(text).format("MM-DD-YYYY"), // Format the date
    },
    {
      title: t("Unit"),
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: t("Selection"),
      dataIndex: "selection",
      key: "selection",
      render: (text: boolean, record: RequirementItem) => {
        if (designation === 'buyer') {
          return (
            <Switch
              checked={text}
              onChange={(checked) => handleSelectionChange(record.id, checked)}
            />
          );
        } else {
          return <span>{text ? t("Selected") : t("Not Selected")}</span>;
        }
      },
        },
  ];

  useEffect(() => {
    if (visible && requirement) {
      fetchFiles();
      fetchRequirementItems();
    }
  }, [visible, requirement]);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      });
  
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };  
  return (
    <Drawer
      title={`${t('Request for Quotation')} ${requirement ? requirement.id : ''}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="90%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        {requirement && (
          <>
             {/* Header Section: Item Description and ID Tender (r_id) */}
        <Descriptions bordered>
          <Descriptions.Item>
            {requirement.item_description || t("No description available")}
          </Descriptions.Item>
          <Descriptions.Item label={t("ID Tender")}>
            {requirement.r_id || t("No ID available")}
          </Descriptions.Item>


        </Descriptions>

        <Descriptions title={t("Requirement Details")} bordered>
  <Descriptions.Item
    label={t("Requirement Type")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.requirement_type}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("PO Number")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.po_number}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Sub Service")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.sub_service}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Sub Supplies")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.sub_supplies}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("R ID")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.r_id}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Business Line")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.business_line.name}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Category")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.category}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Subcategory")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.subcategory}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Buyer")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.buyer?.fullname}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Status")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.status?.name}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Supplier")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.supplier}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Net Value")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.net_value}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Currency")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.currency?.name}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("CECO Owner Approval")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.ceco_owner_approval}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Manager Approval")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.manager_approval}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Controller Approval")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.controller_approval}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("CECO Owner Comments")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.ceco_owner_comments}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Manager Comments")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.manager_comments}
  </Descriptions.Item>

  <Descriptions.Item
    label={t("Controller Comments")}
    labelStyle={{ backgroundColor: "#f0f0f0" }}  // Grey background for label
    contentStyle={{ backgroundColor: "#ffffff" }}  // White background for value
  >
    {requirement.controller_comments}
  </Descriptions.Item>
</Descriptions>

            <br/>
            <Table
              columns={columns}
              dataSource={requirementItems} 
              rowKey="id"
              pagination={false}
            />
            <br/>
            <br/>

            <Tree
              showIcon
              defaultExpandAll={false}
              treeData={treeData}
              switcherIcon={<FolderOutlined style={{ color: "#1890ff" }} />}
            />
          </>
        )}
         <Button type="primary" icon={<DownloadOutlined />} onClick={generatePDF} style={{ marginBottom: 16 }}>
              {t("Download PDF")}
            </Button>
      </div>
    </Drawer>
  );
};

export default RequirementDrawer;